import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function Home() {
   // initial value of users is an empty array
   const session = useSession();
   const router = useRouter();

   if (session.status === 'loading') {
      return <div>Loading...</div>;
   }

   if (session.status === 'unauthenticated') {
      // redirect to login page
      router.push('/login');
   }

   if (session.status === 'authenticated') {
      // redirect to dashboard
      router.push('/dashboard');
   }

   return <></>;
}
